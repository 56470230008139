/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-case-declarations */
import moment from "moment";
import { uniq, findIndex } from "lodash";

export default (initstate, name) =>
  (state = initstate, action) => {
    switch (action.type) {
      case `add${name}`:
        return uniq([...state, action.payload]);
      case `update${name}`:
        state[action.payload.editIndex] = action.payload.data;
        return [...state];
      case `modifyreservationProduct`: //For modify product
        let parentId =
          action.payload.data && action.payload.data.parent_id
            ? action.payload.data.parent_id
            : null;
        let modifyEditIndex = parentId
          ? findIndex(
              state,
              (val) => Number(val.parent_id) === Number(parentId),
            )
          : findIndex(
              state,
              (val) => Number(val.id) === Number(action.payload.data.id),
            );
        modifyEditIndex !== -1
          ? (state[modifyEditIndex] = action.payload.data)
          : (state[state - 1] = action.payload.data);
        return [...state];
      case `set${name}`:
        let updateResult = JSON.parse(JSON.stringify(action.payload));
        return updateResult;
      case `delete${name}`:
        state[action.payload.editIndex]["deleted_at"] = moment().format(
          "YYYY-MM-DD HH:mm:ss",
        );
        return [...state];
      case `remove${name}`:
        state.splice(action.payload, 1);
        return state;
      case `reset${name}`:
        return [];
      default:
        return [...state];
    }
  };
