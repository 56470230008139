import { AUTH_TOKEN } from "../config/constants";

export const expireDate = (num) => {
  num = num * 1000;
  var now = Date.now();
  num = now + num;
  var date = new Date(num);
  return date.toString();
};

export const isExpire = () =>
  new Date(localStorage.getItem("expires_in")) > Date.now() ? false : true;

export const isAuthenticated = () =>
  localStorage.getItem(AUTH_TOKEN) && !isExpire() ? true : false;
