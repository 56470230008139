import React, { useState, useEffect } from "react";
import { Button, Table } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CREATE_VALID_DATE,
  GET_VALID_DATE,
} from "../../../queries/DateQuery.js";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { toMySqlDateTime } from "../../../utils/DateFormat";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import moment from "moment";
import styled from "styled-components";
import {
  ALLOW_VALID_DATE_IDENTIFIER,
  IDENTIFIER,
} from "../../../config/constants.js";

const ValidDate = () => {
  const [productDate, setProductDate] = useState(null);
  const [productDateTime, setProductDateTime] = useState(
    setHours(setMinutes(new Date(), 30), 16),
  );
  const [transactionDate, setTransactionDate] = useState(null);
  const [transactionDateTime, setTransactionDateTime] = useState(
    setHours(setMinutes(new Date(), 30), 16),
  );
  const [productInfo, setProductInfo] = useState(null);
  const [transactionInfo, setTransactionInfo] = useState(null);

  const handleProductDateSelect = (date) => {
    setProductDateTime(toMySqlDateTime(date));
    setProductDate(date);
  };

  const handleProductDateChange = (date) => {
    setProductDateTime(toMySqlDateTime(date));
    setProductDate(date);
  };

  const handleTransactionDateChange = (date) => {
    setTransactionDate(date);
    setTransactionDateTime(toMySqlDateTime(date));
  };

  const handleTransactionDateSelect = (date) => {
    setTransactionDate(date);
    setTransactionDateTime(toMySqlDateTime(date));
  };

  const { data } = useQuery(GET_VALID_DATE, {
    fetchPolicy: "network-only",
    variables: {
      valid_type: "PRODUCT",
    },
  });
  const { data: data2 } = useQuery(GET_VALID_DATE, {
    fetchPolicy: "network-only",
    variables: {
      valid_type: "TRANSACTION",
    },
  });

  useEffect(() => {
    if (data?.validDate?.valid_type === "PRODUCT") {
      setProductInfo(data?.validDate);
      setProductDate(data?.validDate?.valid_date);
    }
    if (data2?.validDate?.valid_type === "TRANSACTION") {
      setTransactionInfo(data2?.validDate);
      setTransactionDate(data2?.validDate?.valid_date);
    }
  }, [data, data2]);

  const [createValidDate] = useMutation(CREATE_VALID_DATE, {
    onCompleted: (res) => {
      if (res?.createValidDate?.valid_type === "TRANSACTION") {
        setTransactionInfo(res?.createValidDate);
      } else {
        setProductInfo(res?.createValidDate);
      }

      ToastsStore.success("Successfully added valid date", 1000);
    },
    onError: (error) => {
      console.log("error is", error);
      ToastsStore.error(error.message.replace(/GraphQL error:/g, ""));
    },
  });

  return (
    <ValidDateStyle>
      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      />
      {/* {ALLOW_VALID_DATE_IDENTIFIER.includes(
        localStorage.getItem(IDENTIFIER),
      ) ? ( */}
      <Table>
        <tbody>
          <tr>
            <th scope="row">Product</th>
            <td>
              <DatePicker
                selected={productDate ? new Date(productDate) : null}
                value={
                  productDate
                    ? moment(productDate).format("MM/DD/YYYY HH:mm:ss")
                    : null
                }
                shouldCloseOnSelect={false}
                onSelect={(e) => {
                  const dateVal = Array.isArray(e) && e.length > 0 ? e[0] : e;
                  handleProductDateSelect(dateVal);
                }}
                onChange={(e) => {
                  const dateVal = Array.isArray(e) && e.length > 0 ? e[0] : e;
                  handleProductDateChange(dateVal);
                }}
                className="form-control"
                timeFormat="HH:mm"
                timeIntervals={5}
                autoComplete="off"
                popperPlacement="bottom"
                selectsRange={true}
                showTimeSelect={true}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"], // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false, // turn off since needs preventOverflow to be enabled
                  },
                }}
              />
            </td>
            {ALLOW_VALID_DATE_IDENTIFIER.includes(
              localStorage.getItem(IDENTIFIER),
            ) && (
              <td>
                <Button
                  disabled={
                    localStorage.getItem("userRole") === "1" ||
                    localStorage.getItem("userRole") === "5"
                      ? false
                      : true
                  }
                  type="button"
                  color="primary"
                  onClick={() =>
                    createValidDate({
                      variables: {
                        input: {
                          valid_type: "PRODUCT",
                          valid_date: productDateTime,
                        },
                      },
                    })
                  }
                >
                  Apply
                </Button>
              </td>
            )}
            <td>
              Last updated- <br />
              {productInfo?.updated_at
                ? moment(productInfo?.updated_at).format("MM/DD/YYYY HH:mm:ss")
                : ""}
            </td>
            <td>
              Updated by <br />
              {productInfo?.updated_by?.email}
            </td>
          </tr>
          <tr>
            <th scope="row">Operation Transaction</th>
            <td>
              <DatePicker
                selected={transactionDate ? new Date(transactionDate) : null}
                value={
                  transactionDate
                    ? moment(transactionDate).format("MM/DD/YYYY HH:mm:ss")
                    : null
                }
                shouldCloseOnSelect={false}
                onSelect={(e) => {
                  const dateVal = Array.isArray(e) && e.length > 0 ? e[0] : e;
                  handleTransactionDateSelect(dateVal);
                }}
                onChange={(e) => {
                  const dateVal = Array.isArray(e) && e.length > 0 ? e[0] : e;
                  handleTransactionDateChange(dateVal);
                }}
                className="form-control"
                timeFormat="HH:mm"
                timeIntervals={5}
                autoComplete="off"
                popperPlacement="bottom"
                selectsRange={true}
                showTimeSelect={true}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"], // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false, // turn off since needs preventOverflow to be enabled
                  },
                }}
              />
            </td>
            {ALLOW_VALID_DATE_IDENTIFIER.includes(
              localStorage.getItem(IDENTIFIER),
            ) && (
              <td>
                <Button
                  type="button"
                  disabled={
                    localStorage.getItem("userRole") === "1" ||
                    localStorage.getItem("userRole") === "5"
                      ? false
                      : true
                  }
                  color="primary"
                  onClick={() =>
                    createValidDate({
                      variables: {
                        input: {
                          valid_type: "TRANSACTION",
                          valid_date: transactionDateTime,
                        },
                      },
                    })
                  }
                >
                  Apply
                </Button>
              </td>
            )}
            <td>
              Last updated- <br />
              {transactionInfo?.updated_at
                ? moment(transactionInfo?.updated_at).format(
                    "MM/DD/YYYY HH:mm:ss",
                  )
                : ""}
            </td>
            <td>
              Updated by <br />
              {transactionInfo?.updated_by?.email}
            </td>
          </tr>
        </tbody>
      </Table>
      {/* ) : (
        <p style={{ padding: 10 }}>You are not allowed to this page</p>
      )} */}
    </ValidDateStyle>
  );
};

const ValidDateStyle = styled.div`
  .react-datepicker-wrapper {
    width: 65%;
  }
`;

export default ValidDate;
