import { EMPTY_TAB_ID } from "../../config/reservations";
const initialState = EMPTY_TAB_ID;

const ReservationTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationTab":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default ReservationTabReducer;
