import CommonReducer from "../CommonReducer";

const initialState = [];

const ReservationSourceReducer = CommonReducer(
  initialState,
  "reservationSource",
);

export default ReservationSourceReducer;
