import {
  ADD_RESERVATION_PRODUCT,
  EDIT_RESERVATION_PRODUCT,
  MODIFY_EDIT_RESERVATION_PRODUCT,
  REMOVE_RESERVATION_PRODUCT,
  SET_RESERVATION_PRODUCT,
  RESET_RESERVATION_PRODUCT,
  ADD_RESERVATION_REFUND_PRODUCT,
  SET_RESERVATION_REFUND_PRODUCT,
} from "./types";

const addReservationProduct = (product) => {
  return { type: ADD_RESERVATION_PRODUCT, payload: product };
};
const addReservationRefundProduct = (product) => {
  return { type: ADD_RESERVATION_REFUND_PRODUCT, payload: product };
};

const editReservationProduct = (product, editIndex) => {
  return {
    type: EDIT_RESERVATION_PRODUCT,
    payload: { data: product, editIndex: editIndex },
  };
};

const modifyEditReservationProduct = (product, editIndex) => {
  return {
    type: MODIFY_EDIT_RESERVATION_PRODUCT,
    payload: { data: product, editIndex: editIndex },
  };
};

const removeReservationProduct = (editIndex) => {
  return {
    type: REMOVE_RESERVATION_PRODUCT,
    payload: editIndex,
  };
};

const setReservationProduct = (product) => {
  return { type: SET_RESERVATION_PRODUCT, payload: product };
};
const setReservationRefundProduct = (product) => {
  return { type: SET_RESERVATION_REFUND_PRODUCT, payload: product };
};
const resetReservationProduct = () => {
  return { type: RESET_RESERVATION_PRODUCT };
};

export {
  addReservationProduct,
  editReservationProduct,
  modifyEditReservationProduct,
  removeReservationProduct,
  setReservationProduct,
  resetReservationProduct,
  addReservationRefundProduct,
  setReservationRefundProduct,
};
