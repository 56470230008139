import { RESERVATION_DEFAULT_CURRENCY } from "../../config/reservations";

const initialState = RESERVATION_DEFAULT_CURRENCY;

const ReservationCurrencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationCurrency":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default ReservationCurrencyReducer;
