const initialState = null;

const ReservationAssignToReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationAssignTo":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default ReservationAssignToReducer;
