import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ThemeProvider } from "./Theme";

Bugsnag.start({
  logger: null,
  enabledReleaseStages: ["production"],
  releaseStage: process.env.REACT_APP_TRACK_RELEASE_STAGE,
  apiKey: process.env.REACT_APP_TRACK_TOKEN,
  plugins: [new BugsnagPluginReact()],
});
//const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
