import {
  ADD_RESERVATION_TASK,
  EDIT_RESERVATION_TASK,
  REMOVE_RESERVATION_TASK,
  SET_RESERVATION_TASK,
  RESET_RESERVATION_TASK,
} from "./types";

const addReservationTask = (task) => {
  return { type: ADD_RESERVATION_TASK, payload: task };
};

const editReservationTask = (task, editIndex) => {
  return {
    type: EDIT_RESERVATION_TASK,
    payload: { data: task, editIndex: editIndex },
  };
};

const setReservationTask = (task) => {
  return { type: SET_RESERVATION_TASK, payload: task };
};

const removeReservationTask = (editIndex) => {
  return {
    type: REMOVE_RESERVATION_TASK,
    payload: editIndex,
  };
};

const resetReservationTask = () => {
  return { type: RESET_RESERVATION_TASK };
};

export {
  addReservationTask,
  editReservationTask,
  setReservationTask,
  removeReservationTask,
  resetReservationTask,
};
