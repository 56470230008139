const initialState = [0, 0, 0, 0, 0, 0, 0];

const ReservationSubTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationSubTab":
      state[action.payload.tabIndex] = action.payload.index;
      return [...state];
    default:
      return state;
  }
};

export default ReservationSubTabReducer;
