import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { AUTH_TOKEN, TOKEN_TYPE } from "./config/constants";
import { createUploadLink } from "apollo-upload-client";

const createApolloClient = (endpoint) => {
  const authLink = setContext((_, { headers }) => {
    const tokenType = localStorage.getItem(TOKEN_TYPE);
    const token = localStorage.getItem(AUTH_TOKEN);
    return {
      headers: {
        ...headers,
        authorization: token ? `${tokenType} ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(
      ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(
              ({ debugMessage, message, locations, path }) => {
                if (debugMessage && debugMessage === "Unauthenticated.") {
                  localStorage.removeItem(AUTH_TOKEN);
                  window.location.href = "/";
                }

                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
              },
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        createUploadLink({
          uri: process.env.REACT_APP_BACKEND_SERVER + endpoint,
        }),
      ]),
    ),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
