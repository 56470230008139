import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "../utils/withRouter";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  accnt: PropTypes.bool,
};
const defaultProps = {
  accnt: false,
};

const ThemeChangeDropdown = ({ theme, toggleTheme }) => {
  return (
    <ThemeSwitchStyle>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button
          size="sm"
          type="button"
          className={`btn btn-primary ${
            theme === "light-theme" ? "active" : ""
          }`}
          onClick={toggleTheme}
          disabled={theme === "light-theme" ? true : false}
        >
          <FontAwesomeIcon icon={faSun} />
        </button>
        <button
          size="sm"
          type="button"
          className={`btn btn-primary ${
            theme === "dark-theme" ? "active" : ""
          }`}
          onClick={toggleTheme}
          disabled={theme === "dark-theme" ? true : false}
        >
          <FontAwesomeIcon icon={faMoon} />
        </button>
      </div>
    </ThemeSwitchStyle>
  );
};

const ThemeSwitchStyle = styled.div`
  .btn-group {
    margin: 9px 0 9px 0;
    button {
      padding: 5px;
      display: table;
      svg {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
`;

ThemeChangeDropdown.propTypes = propTypes;
ThemeChangeDropdown.defaultProps = defaultProps;

export default withRouter(ThemeChangeDropdown);
