const initialState = { eticket: false, reserve: false };

const ReservationEmailStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationEmailStatus":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default ReservationEmailStatusReducer;
