import moment from "moment";

export const formatDate = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("DD/MMM/YYYY") : "";

export const toMySqlDate = (date) =>
  date ? moment(date, "DD/MMM/YYYY").format("YYYY-MM-DD") : "";

export const formatPromoDate = (date) =>
  date ? moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : "";

export const formatDateTime = (date) =>
  date
    ? moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MMM/YYYY HH:mm:ss")
    : "";

export const formatDateTimeSec = (date) =>
  date
    ? moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MMM/YYYY HH:mm:ss")
    : "";

export const toMySqlDateTime = (date) =>
  date ? moment(date, "DD/MM/YYYY h:mm ").format("YYYY-MM-DD HH:mm:ss") : "";

export const toMySqlDateTimeL = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm") : "";

export const formatValidDate = (date) =>
  date ? moment(date, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY HH:mm:ss") : "";
