import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "../utils/withRouter";
import { isAuthenticated } from "../lib/auth";
import { AUTH_TOKEN, IDENTIFIER } from "../config/constants";
import { useMutation } from "@apollo/react-hooks";
import { LOGOUT_QUERY } from "../queries/LoginQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faLock } from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  accnt: PropTypes.bool,
};
const defaultProps = {
  accnt: false,
};

const DefaultHeaderDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const [logout] = useMutation(LOGOUT_QUERY, {
    onCompleted: (res) => {
      console.log(res);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const dropAccnt = () => {
    return (
      <Dropdown className="ml-auto" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav>
          {localStorage.getItem(IDENTIFIER)
            ? localStorage.getItem(IDENTIFIER) + " "
            : ""}
          <FontAwesomeIcon icon={faCircleUser} size="lg" />
        </DropdownToggle>
        <DropdownMenu>
          {isAuthenticated() ? (
            <>
              <DropdownItem
                onClick={() => {
                  logout();
                  if (localStorage.getItem(AUTH_TOKEN)) {
                    localStorage.clear();
                    window.location.href = "/";
                  }
                }}
              >
                <FontAwesomeIcon icon={faLock} /> Logout
              </DropdownItem>
            </>
          ) : (
            <Link to={"/login"}>
              <DropdownItem onClick={props.onLogin}>
                <FontAwesomeIcon icon={faLock} /> Login
              </DropdownItem>
            </Link>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  };

  const { accnt } = props;

  return accnt ? dropAccnt() : null;
};

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default withRouter(DefaultHeaderDropdown);
