import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    align-items: center;
    background: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }

  .card-header {
    background-color: ${({ theme }) => theme.secondary} !important;
  }

  .modal-body, .modal-dialog, .modal-footer {
    background-color: ${({ theme }) => theme.body} !important;
  }

  .modal-header {
    background-color: ${({ theme }) => theme.secondary} !important;
  }

  .modal-content {
    border: 0!important;
  }

  .form-control {
    background-color: ${({ theme }) => theme.input} !important;
    color: ${({ theme }) => theme.text} !important;
    border: 1px solid ${({ theme }) => theme.border} !important;
  }

  .common-select {
    .Select-control,.css-1s2u09g-control,.css-1pahdxg-control, .css-13cymwt-control,.css-16xfy0z-control,.css-t3ipsp-control {
      background-color: ${({ theme }) => theme.input} !important;
      border: 1px solid ${({ theme }) => theme.border} !important;
    }
  }
  .css-1dimb5e-singleValue{
    color:${({ theme }) => theme.text} !important;
  }

  .Select-menu-outer,.css-26l3qy-menu,.css-1n6sfyn-MenuList {
    background: ${({ theme }) => theme.secondary} !important;
    .Select-menu {
      .Select-option {
        background: ${({ theme }) => theme.secondary} !important;
        color: ${({ theme }) => theme.text} !important;
      }
      .Select-option:hover {
        background: ${({ theme }) => theme.active} !important;
      }
    }
  }

  .css-1nmdiq5-menu{
    background: ${({ theme }) => theme.secondary} !important;
  }
  .css-1n7v3ny-option, css-d7l1ni-option,
 .css-9gakcf-option:hover,.css-d7l1ni-option:hover
 {
    background-color:   ${({ theme }) => theme.active} !important;
    cursor:pointer !important;
    color:  ${({ theme }) => theme.text} !important;
  }
  .css-tr4s17-option{
    background: transparent !important;
    color: ${({ theme }) => theme.active} !important;  
  }
  .css-d7l1ni-option{
    background: ${({ theme }) => theme.secondary} !important;
    color: ${({ theme }) => theme.text} !important;  }

  .react-phone-div .phone_prefix .react-tel-input {
    .form-control {
      background-color: ${({ theme }) => theme.input}!important;
      border: 1px solid ${({ theme }) => theme.border} !important;
    }
    .flag-dropdown {
      background-color: ${({ theme }) => theme.input}!important;
      border: 1px solid ${({ theme }) => theme.border}!important;
      .country-list {
        background-color: ${({ theme }) => theme.secondary} !important;
        text-align: left;
        .dial-code {
          color: #fff !important;
        }
        .highlight {
          .country-name {
            color: ${({ theme }) => theme.secondary} !important;
          }
          .dial-code {
            color: ${({ theme }) => theme.secondary} !important;
          }
        }
        li:hover {
          .country-name {
            color: ${({ theme }) => theme.secondary} !important;
          }
          .dial-code {
            color: ${({ theme }) => theme.secondary} !important;
          }
        }
      }
    }
  }

  .error-txt {
    color:  ${({ theme }) => theme.required} !important;
  }
  .note-txt {
    color:  #fff !important;
  }

  .form-control[readonly], .css-1insrsq-control, .form-control:disabled, 
  .prices-div .css-1insrsq-control, .prices-div .form-control[readonly] {
    border: 1px solid ${({ theme }) => theme.border} !important;
    background-color: ${({ theme }) => theme.secondary} !important;
    opacity: 1 !important;
    color: ${({ theme }) => theme.text} !important;
    pointer-events: initial !important;
    cursor: not-allowed !important;
  }

  .product-modal-show {
    color:  ${({ theme }) => theme.active} !important;
  }

  .switch-fees {
    color:  ${({ theme }) => theme.active} !important;
  }
`;
