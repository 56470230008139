import { gql } from "apollo-boost";

const LOGIN_QUERY = gql`
  mutation ($input: LoginInput) {
    login(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        arrangers {
          id
        }
      }
    }
  }
`;

const SOCIAL_LOGIN = gql`
  mutation ($input: SocialLoginInput!) {
    socialLogin(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        arranger {
          id
        }
      }
    }
  }
`;

const LOGOUT_QUERY = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`;

const FORCE_LOGOUT = gql`
  mutation ($input: forceLogoutInput) {
    forceLogout(input: $input) {
      id
      email
    }
  }
`;

export { LOGIN_QUERY, SOCIAL_LOGIN, LOGOUT_QUERY, FORCE_LOGOUT };
