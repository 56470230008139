import React, { useEffect, createContext, useState } from "react";

const ThemeContext = createContext();

const getTheme = () => {
  const theme = localStorage.getItem("theme");
  if (!theme) {
    // Default theme is taken as dark-theme
    localStorage.setItem("theme", "dark-theme");
    return "dark-theme";
  } else {
    return theme;
  }
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getTheme);

  function toggleTheme() {
    if (theme === "dark-theme") {
      setTheme("light-theme");
      localStorage.setItem("theme", "light-theme");
    } else {
      setTheme("dark-theme");
      localStorage.setItem("theme", "dark-theme");
    }
  }

  useEffect(() => {
    const refreshTheme = () => {
      localStorage.setItem("theme", theme);
    };

    refreshTheme();
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const lightTheme = {
  body: "#ffffff",
  text: "#000000",
  secondary: "#ebedef",
  border: "rgba(0, 0, 0, 0.2)",
  input: "#ffffff", //--input-color-light
  active: "#20a8d8", //--active-blue-color
  required: "#c77171", //--active-red-color
};

export const darkTheme = {
  body: "#3a4149",
  text: "#ffffff",
  secondary: "#343b41",
  border: "rgba(0, 0, 0, 0.5)",
  input: "#515b65", //--input-color-dark
  active: "#20a8d8",
  required: "#c77171",
};

export { ThemeContext, ThemeProvider };
