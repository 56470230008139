const initialState = null;

const ReservationFinancialStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationFinancialStatus":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default ReservationFinancialStatusReducer;
