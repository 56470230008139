const initialState = null;

const ReservationArrangerSnapshotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setreservationArrangerSnapshot":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default ReservationArrangerSnapshotReducer;
