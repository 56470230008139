import React, {
  Suspense,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { GET_USER_BY_ID } from "../queries/UserQuery";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  LOGIN_USER_ID,
  IDENTIFIER,
  AUTH_TOKEN,
  ALLOW_FEEDBACK,
  ALLOW_ARRANGER_MERGE,
  ALLOW_HR_IDENTIFIER,
  ALLOW_OFFLINE_PRODUCT_TYPE,
  ALLOW_CRAWLED_ROUTES,
  ALLOW_AIRLINE_UPDATE_IDENTIFIER,
  ALLOW_VIBER_MSG_IDENTIFIER,
} from "../config/constants";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import ThemeChangeDropdown from "./ThemeChangeDropdown";
import { connect } from "react-redux";
// sidebar nav config
import Nav from "../_nav";
import NavSuper from "../_navsuper";
// routes config
import routes from "../routes";
import { isAuthenticated } from "../lib/auth";
import LoadingSpinner from "../components/LoadingSpinner";
import styled from "styled-components";
import {
  EMPTY_TAB_ID,
  RESERVATION_DEFAULT_CURRENCY,
} from "../config/reservations";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {
  setReservationId,
  setReservationStatus,
  setReservationFinancialStatus,
  setReservationCheckedUnchecked,
  setReservationAssignTo,
  setReservationArranger,
  setReservationArrangerSnapshot,
  setReservationSalesChannel,
  setReservationLogs,
  setReservationTotal,
  setReservationBalance,
  setReservationFinancialBalance,
  setReservationTab,
  setReservationCurrency,
  setReservationExchangeRate,
  setReservationEmailStatus,
  setreservationTransactionSave,
  setreservationProductSave,
} from "../actions/ReservationAction";
import { resetReservationProduct } from "../actions/ReservationProductAction";
import { resetReservationSource } from "../actions/ReservationSourceAction";
import { resetReservationTask } from "../actions/ReservationTaskAction";
import { resetReservationTransaction } from "../actions/ReservationTransactionAction";
import { join } from "lodash";

const DefaultLayout = (props) => {
  const breadcrumbs = useBreadcrumbs(routes);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [getUsers] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: (response) => {
      response.user &&
        response.user.roles &&
        response.user.roles.length > 0 &&
        setUserRole(response.user.roles[0].id);
      response.user.roles.length > 0 &&
        localStorage.setItem("userRole", response.user.roles[0].id);
      response.user.roles.length > 0 &&
        localStorage.setItem(
          "userRoles",
          join(
            response.user.roles.map((r) => r.id),
            ", ",
          ),
        );
      localStorage.setItem("userName", response?.user?.email);
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const mounted = useRef();
  useEffect(() => {
    if (!localStorage.getItem(AUTH_TOKEN)) {
      navigate("/login");
    }

    if (!mounted.current)
      localStorage.getItem(LOGIN_USER_ID) &&
        localStorage.getItem(LOGIN_USER_ID) !== "" &&
        getUsers({
          variables: {
            id: localStorage.getItem(LOGIN_USER_ID),
          },
        });
  }, [getUsers, navigate]);
  const [isOpen, setIsOpen] = useState(false);
  const [isReservation, setIsReservation] = useState(false);
  const [isChangeUrl, setIsChangeUrl] = useState(false);
  const [nav, setNav] = useState(NavSuper);
  const location = useLocation();

  let checkDirty = (data) => {
    if (data === undefined) {
      return false;
    }
    let obj = JSON.parse(JSON.stringify(data));
    let returnValue = false;
    Object.keys(obj).map((key, index) => {
      obj[key] = obj[key].filter((item) => item && item.id === null);
      obj[key].map((item) => {
        if (Object.keys(item).length > 1) {
          returnValue = true;
        }
        return true;
      });
      return true;
    });
    return returnValue;
  };

  const handleLeave = useCallback(() => {
    //set state empty
    props.dispatch(resetReservationProduct());
    props.dispatch(resetReservationTransaction());
    props.dispatch(resetReservationSource());
    props.dispatch(resetReservationTask());
    props.dispatch(setReservationId(null));
    props.dispatch(setReservationStatus(null));
    props.dispatch(setReservationFinancialStatus(null));
    props.dispatch(setReservationAssignTo(null));
    props.dispatch(setReservationCheckedUnchecked(null));
    props.dispatch(setReservationArranger(null));
    props.dispatch(setReservationArrangerSnapshot(null));
    props.dispatch(setReservationSalesChannel(null));
    props.dispatch(setReservationLogs([]));
    props.dispatch(setReservationTab(EMPTY_TAB_ID));
    props.dispatch(
      setReservationEmailStatus({
        eticket: false,
        reserve: false,
      }),
    );
    props.dispatch(
      setReservationTotal({ home_amount: 0, show_fare_amount: 0 }),
    );
    props.dispatch(
      setReservationBalance({ home_amount: 0, show_fare_amount: 0 }),
    );
    props.dispatch(
      setReservationFinancialBalance({ home_amount: 0, show_fare_amount: 0 }),
    );
    props.dispatch(setReservationCurrency(RESERVATION_DEFAULT_CURRENCY));
    props.dispatch(setReservationExchangeRate(1));
    props.dispatch(setreservationTransactionSave(false));
    props.dispatch(setreservationProductSave(false));

    setIsOpen(false);
  }, [props]);

  let isdirty = checkDirty(props.data);
  useEffect(() => {
    let currentUrl = location.pathname.split("/");
    if (
      currentUrl.length > 0 &&
      (currentUrl[1] !== "reservations" ||
        (currentUrl[1] === "reservations" && !currentUrl[2]))
    ) {
      setIsChangeUrl(true);
      isdirty && isChangeUrl && isReservation && handleLeave();
    } else {
      setIsChangeUrl(false);
    }
    if (userRole === "1") {
      let superAdminNav = NavSuper;

      if (
        ![
          "joost.de.kruijff@flymya.co",
          "htut.khaung.kyaw.oo@flymya.co",
          "myat.thu.thu.htoon@flymya.co",
          "naing.ye.oo@flymya.co",
          "phyu.thinn.aung@flymya.co",
          "moe.myint.aung@flymya.co",
        ].includes(localStorage.getItem(IDENTIFIER))
      ) {
        superAdminNav = {
          items: NavSuper.items.map((i) => {
            if (i.name === "Setup") {
              i.children = i.children.filter((i) => i.name !== "Permissions");
            }
            return i;
          }),
        };
      }
      if (
        ![
          "tim.duits@flymya.co",
          "htut.khaung.kyaw.oo@flymya.co",
          "myat.thu.thu.htoon@flymya.co",
          "theint.myat@flymya.co",
          "aye.nandar@flymya.co",
          "moe.myint.aung@flymya.co",
          "may.myat.mon@flymya.co",
          "thaw.zin.lin@flymya.co",
        ].includes(localStorage.getItem(IDENTIFIER))
      ) {
        superAdminNav = {
          items: NavSuper.items.map((i) => {
            if (i.name === "Setup") {
              i.children = i.children.filter((i) => i.name !== "Clients");
            }
            return i;
          }),
        };
      }
      if (!ALLOW_HR_IDENTIFIER.includes(localStorage.getItem(IDENTIFIER))) {
        superAdminNav = {
          items: NavSuper.items.filter((item_) => {
            return item_.name !== "HR";
          }),
        };
      }

      if (
        !ALLOW_VIBER_MSG_IDENTIFIER.includes(localStorage.getItem(IDENTIFIER))
      ) {
        superAdminNav = {
          items: NavSuper.items.map((i) => {
            if (i.name === "Setup") {
              i.children = i.children.filter(
                (i) => i.name !== "Automated Message Template",
              );
            }
            return i;
          }),
        };
      }
      if (
        !ALLOW_OFFLINE_PRODUCT_TYPE.includes(localStorage.getItem(IDENTIFIER))
      ) {
        superAdminNav = {
          items: NavSuper.items.map((item_) => {
            if (item_.name === "Setup") {
              item_.children = item_.children.filter((i) => {
                if (i.name === "Offline Products") {
                  i.children = i.children.filter(
                    (v) => v.name !== "Offline Product Types",
                  );
                }
                return i;
              });
            }
            return item_;
          }),
        };
      }
      if (!ALLOW_CRAWLED_ROUTES.includes(localStorage.getItem(IDENTIFIER))) {
        superAdminNav = {
          items: NavSuper.items.map((item_) => {
            if (item_.name === "Setup") {
              item_.children = item_.children.filter(
                (i) => i.name !== "Crawled Routes",
              );
            }
            return item_;
          }),
        };
      }
      // if (!ALLOW_ARRANGER_MERGE.includes(localStorage.getItem(IDENTIFIER))) {
      //   superAdminNav = {
      //     items: NavSuper.items.map((item_) => {
      //       if (item_.name === "Setup") {
      //         item_.children = item_.children.filter(
      //           (i) => i.name !== "Arranger Merge",
      //         );
      //       }
      //       return item_;
      //     }),
      //   };
      // }
      if (
        !ALLOW_AIRLINE_UPDATE_IDENTIFIER.includes(
          localStorage.getItem(IDENTIFIER),
        )
      ) {
        superAdminNav = {
          items: NavSuper.items.map((item_) => {
            if (item_.name === "Setup") {
              item_.children = item_.children.filter(
                (i) => i.name !== "Airlines",
              );
            }
            return item_;
          }),
        };
      }
      setNav(superAdminNav);
    } else {
      let simpleNav = Nav;
      if (!ALLOW_FEEDBACK.includes(localStorage.getItem(IDENTIFIER))) {
        simpleNav = {
          items: Nav.items.map((item_) => {
            if (item_.name === "Setup") {
              item_.children = item_.children.filter(
                (i) => i.name !== "Feedback",
              );
            }
            return item_;
          }),
        };
      }
      if (!ALLOW_CRAWLED_ROUTES.includes(localStorage.getItem(IDENTIFIER))) {
        simpleNav = {
          items: Nav.items.map((item_) => {
            if (item_.name === "Setup") {
              item_.children = item_.children.filter(
                (i) => i.name !== "Crawled Routes",
              );
            }
            return item_;
          }),
        };
      }
      // if (!ALLOW_ARRANGER_MERGE.includes(localStorage.getItem(IDENTIFIER))) {
      //   simpleNav = {
      //     items: Nav.items.map((item_) => {
      //       if (item_.name === "Setup") {
      //         item_.children = item_.children.filter(
      //           (i) => i.name !== "Arranger Merge",
      //         );
      //       }
      //       return item_;
      //     }),
      //   };
      // }
      if (
        !ALLOW_AIRLINE_UPDATE_IDENTIFIER.includes(
          localStorage.getItem(IDENTIFIER),
        )
      ) {
        simpleNav = {
          items: Nav.items.map((item_) => {
            if (item_.name === "Setup") {
              item_.children = item_.children.filter(
                (i) => i.name !== "Airlines",
              );
            }
            return item_;
          }),
        };
      }
      setNav(simpleNav);
      // setNav(NavSuper);
    }
    return () => {
      if (
        currentUrl.length > 0 &&
        ((currentUrl[1] === "reservations" && currentUrl[2] === "create") ||
          (currentUrl[1] === "reservations" && currentUrl[2] === "edit"))
      ) {
        setIsReservation(true);
        isdirty && isChangeUrl && isReservation && handleLeave();
      } else {
        if (!isdirty && isChangeUrl && isReservation) {
          handleLeave();
        }
        setIsReservation(false);
      }
    };
  }, [
    isdirty,
    isReservation,
    props,
    handleLeave,
    userRole,
    isChangeUrl,
    location.pathname,
  ]);

  const handleStay = () => {
    props.history.goBack();
    setIsOpen(false);
  };

  useEffect(() => {
    let currentUrl = location.pathname.split("/");
    if (
      currentUrl.length > 0 &&
      currentUrl[1] === "reservations" &&
      currentUrl[2] === "edit"
    ) {
      routes.map((r) => {
        r.path === "/reservations/edit/:id" && (r.name = currentUrl[3]);
        return r;
      });
    }
  }, [location.pathname]);

  return (
    <DefaultLayoutStyle>
      <div className="app">
        <Modal isOpen={isOpen}>
          <ModalBody>
            You have unsaved changes. Are you sure you want to leave without
            saving??
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleStay}>
              Stay
            </Button>{" "}
            <Button color="secondary" onClick={handleLeave}>
              Leave
            </Button>
          </ModalFooter>
        </Modal>

        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav navConfig={nav} location={window.location} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <div className="top-header">
              <Breadcrumb>
                {breadcrumbs.map(({ match, breadcrumb }, key) =>
                  key + 1 === breadcrumbs.length ? (
                    <BreadcrumbItem key={key}>{breadcrumb}</BreadcrumbItem>
                  ) : !breadcrumb.key.includes("edit") ? (
                    <BreadcrumbItem key={key}>
                      <NavLink key={key} to={match.pathname}>
                        {breadcrumb}
                      </NavLink>
                    </BreadcrumbItem>
                  ) : breadcrumb.key.includes("edit") ? (
                    <BreadcrumbItem key={key}>{breadcrumb}</BreadcrumbItem>
                  ) : (
                    ""
                  ),
                )}
              </Breadcrumb>
              {(window.location.hash.includes("reservations/edit") ||
                window.location.hash.includes("reservations/create")) && (
                <div className="flight-info-bar">
                  <a
                    href="https://www.notion.so/flymya/Flight-Suppliers-Information-0ca7af4a329e4c0c99ff407a1613db24"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="supplier-btn"
                  >
                    Flight Supplier Contact Details
                  </a>
                  <a
                    href="https://www.notion.so/B2B-Agents-Corporates-Contact-Details-80969c508a274095a77e7d54e939e245"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="supplier-btn agent-btn"
                  >
                    B2B Agents & Corporates Contact Details
                  </a>
                </div>
              )}
              <div className="top-right-header">
                <ThemeChangeDropdown
                  theme={props.theme}
                  toggleTheme={props.toggleTheme}
                />
                <DefaultHeaderDropdown onLogout={props.onLogout} accnt />
              </div>
            </div>
            <Container fluid>
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  {routes.map((route, idx) => {
                    if (!isAuthenticated()) {
                      return <Route key={idx} to="/login" />;
                    }
                    return route.element ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        element={<route.element />}
                      />
                    ) : null;
                  })}
                  <Route
                    path="/"
                    element={<Navigate to="/reservations" />}
                    replace
                  />
                </Routes>
              </Suspense>
            </Container>
          </main>
        </div>
      </div>
    </DefaultLayoutStyle>
  );
};

const DefaultLayoutStyle = styled.div`
  .top-header {
    width: 100%;
    display: flex;
    background-color: #3a4149;
    height: 46px;
    margin: 0 0 0 0;
  }

  .top-header .dropdown.nav-item {
    margin-left: auto;
    display: flex;
  }

  .top-header .dropdown.nav-item img {
    max-width: 50px;
    max-height: 30px;
  }

  .flight-info-bar {
    margin-top: 12px;
  }
  .supplier-btn {
    font-size: 13px;
    background-color: var(--activeColor);
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-right: 5px;
  }
`;

const mapStateToProps = (state) => {
  return {
    data: {
      reservationProduct: state.reservationProduct,
      reservationTransaction: state.reservationTransaction,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
